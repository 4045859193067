export const Images = [
    {
        id: 1,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1pw9QWDJjyUznbCajv_LiWV4SJpBRM16s",
        name: 'album2022'
        
    },
    {
        id: 2,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1KKWJYlJwWvpEoTBmLYYqfZgZqzYXRGLL",
        name: 'album2022'

    },
    {
        id: 3,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1-cQxk0cYXTSbOIBsf3Mx6WxWXnA6vl8q",
        name: 'album2022'

    },
    {
        id: 4,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1LiAIdB2qvVYjTs8xgwgyP6DE45MyRthV",
        name: 'album2022'

    },
    {
        id: 5,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1OpE8g5eh0Zqa65uKH4ZWSPY560V-CDaa",
        name: 'album2022'

    },
    {
        id: 6,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1brr-VlG3oP45FvL-mGPStx6uyZLTpD4j",
        name: 'album2022'

    },
    {
        id: 7,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=12CAuLJAxyQ7DHnJ30fuLuSX5t6MnOnRD",
        name: 'album2022'

    },
    {
        id: 8,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1hj5uOMgYn2JPsObJlDedNfLRbPny7wMg",
        name: 'album2022'

    },
    {
        id: 9,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1Nn8DbtsRvrBWTecJpxn6PYanbOV63WmL",
        name: 'album2022'

    },
    {
        id: 10,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1nZwILbKGiPJ0LGHeq22HXleIj-2HndEk",
        name: 'album2022'

    },
    {
        id: 11,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=10ZQqfy2NTDKkq-eOe1xXKT8te359ANVW",
        name: 'album2022'

    },
    {
        id: 12,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1NF65w-vPb9Ks8xa1YH7gg_GTbCNAZgI7",
        name: 'album2022'

    },
    {
        id: 13,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=14kKltpHnD0HBpwyE9UYXRCz3CTpJLXRk",
        name: 'album2022'

    },
    {
        id: 14,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1xh9E7xK8V2cdUbvLNBTJ827GvFYdb492",
        name: 'album2022'

    },
    {
        id: 15,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1YcN827NRQKoCpxQownc471_sH_NoDBvA",
        name: 'album2022'

    },
    {
        id: 16,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1XFJg6w81fOEbeiNpDa3F-BjxinX_X847",
        name: 'album2022'

    },
    {
        id: 17,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1AfydaiG_xhLqLNv_OHiU6rgmLsnwk40f",
        name: 'album2022'

    },
    {
        id: 18,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1TzWLFAcvJsJVVjWwkXVsYC3F0QVMR9xb",
        name: 'album2022'

    },
    {
        id: 19,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1dlcoS9ddDS7atZ5vDj0oAgPzLxGyQv9A",
        name: 'album2022'

    },
    // {
    //     id: 20,
    //     photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=10IARBWKpg410gFbhO6vdiHWpPdoK1p1k  ",
    //     name: 'album2022'

    // },
    {
        id: 21,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1MaszFP1SmdoZJ1AvYtRnq3sSXF1_-Pxt",
        name: 'album2022'

    },
    // {
    //     id: 22,
    //     photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1axWETL5-XdoO-BwNuD6Th1qERYC9QdTF",
    //     name: 'album2022'

    // },
    {
        id: 23,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1u_UKYlKNtwghH4_cQraARR9-2p725ccs",
        name: 'album2022'

    },
    {
        id: 24,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1phlwYgbjVA-bherJq8k5mcBSoBbHIB1W",
        name: 'album2022'

    },
    {
        id: 25,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1OaZp8ODZ0ogSzYK8KZ3edtUqpTGfzlip",
        name: 'album2022'

    },
    {
        id: 26,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1gI3y0W42lQUrGgv-4dFVW52fNQbwY6l-",
        name: 'album2022'

    },
    {
        id: 27,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1NF65w-vPb9Ks8xa1YH7gg_GTbCNAZgI7",
        name: 'album2022'

    },
    {
        id: 28,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1X4BW2yPc4HYhwt18AuDsh_zKDcwbfq1B",
        name: 'album2022'

    },
    {
        id: 29,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1R3EXHOeZvPJTc7ILEIzQBRNJNcX6FAq1",
        name: 'album2022'

    },
    {
        id: 30,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1oRi0n3jg0bMLgfBemqPldWBwqDN4s67h",
        name: 'album2022'

    },
    {
        id: 31,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1rWN6C_jEsvdsZPhoRbGns2-9TZ7YkwV5",
        name: 'album2022'

    },
    {
        id: 32,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=119eN3_XDDMj-X6KAjVTPsg_wC9hKjLlA",
        name: 'album2022'

    },
    {
        id: 33,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1gzInMSytqOXDniQTDZb8fgqZsFt9Zfe2",
        name: 'album2022'

    },
    {
        id: 34,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1Ji4jtPl2mTSyJsgI7JrW6KFo8X6Pg7-Y",
        name: 'album2022'

    },
    {
        id: 35,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=14PoP1OV0S_kYgVfcw0NzLR26FJx-Nm0q",
        name: 'album2022'

    },
    {
        id: 36,
        photo: "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1AkqI9mWujhBnjg9lyyxmyc4J8--pRAyZ",
        name: 'album2022'

    },
      {
        "id": 37,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=13IzXwy0qtmqm5XTPhM7jQfUuSj1C4Dom",
        "name": "alumni2012"
      },
      {
        "id": 38,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=13IzXwy0qtmqm5XTPhM7jQfUuSj1C4Dom",
        "name": "alumni2012"
      },
      {
        "id": 39,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=13IzXwy0qtmqm5XTPhM7jQfUuSj1C4Dom",
        "name": "alumni2012"
      },
      {
        "id": 40,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=15fo75_Og_q4ouyZeLYgXk6te0Xg03DTK",
        "name": "alumni2012"
      },
      {
        "id": 41,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=18sMWEXm0vOfdBJRFLDUOPMOTy-uWu2gr",
        "name": "alumni2012"
      },
      {
        "id": 42,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=19WLZSCapfWSPR53ZKHaXXLtWKs3tZiyE",
        "name": "alumni2012"
      },
      {
        "id": 43,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1D4P-qTOVZCsRX3cfeOL5a13WFoaR46DZ",
        "name": "alumni2012"
      },
      {
        "id": 44,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1F9ViKgmfZPhjWCKP2G1Bz5Nrh6PFaFWx",
        "name": "alumni2012"
      },
      {
        "id": 45,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1G14GiYZzLcDmUFQs9J67TkDIg7Rj7M04",
        "name": "alumni2012"
      },
      {
        "id": 46,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1HxyLHJFHkg_Z6iHCkdSXZJUOzIV-YrEe",
        "name": "alumni2012"
      },
      {
        "id": 47,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1IOeMut3rG09tIlc8hbTHxhsJKch5OXwt",
        "name": "alumni2012"
      },
      {
        "id": 48,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1IP2kBlmh--4jgSdWl7t3_E3UqkSF6TEB",
        "name": "alumni2012"
      },
      {
        "id": 49,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1K1A6Jpp6cbWLjmTkyiKdJ3n8rmuPKkG3",
        "name": "alumni2012"
      },
      {
        "id": 50,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1LFvN5UJIS2Gmn6y4Dgdqowkms1aZMZho",
        "name": "alumni2012"
      },
      {
        "id": 51,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1Le99841SnBmqrjyNti52J6xjSz6Fpcz6",
        "name": "alumni2012"
      },
      {
        "id": 52,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1LoH09lKdTbsHVBoaqfba1vxUtngxGVSL",
        "name": "alumni2012"
      },
      {
        "id": 53,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1NGenzrZ26XQhsBfrgsOEUD3wf_C1rShD",
        "name": "alumni2012"
      },
      {
        "id": 54,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1NTrsWWjif17hRX_eG-SeV53OU74h7m4_",
        "name": "alumni2012"
      },
      {
        "id": 55,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1U7eGL7b4f0YAF-JoYBZj0jFFl3yFuvK3",
        "name": "alumni2012"
      },
      {
        "id": 56,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1UnSGNaHUvwaYDO3hkieF3K34wyDVRM4o",
        "name": "alumni2012"
      },
      {
        "id": 57,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1V_A-a5Ak_iKXrZM_2WtROjuDLq7576pZ",
        "name": "alumni2012"
      },
      {
        "id": 58,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1YIPk6TZZxELrByMXR1CdSUcBTEfwfhVQ",
        "name": "alumni2012"
      },
      {
        "id": 59,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1ZGH71ZYeY3zOhcXEW17w6BYuism_Ud4O",
        "name": "alumni2012"
      },
      {
        "id": 60,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1bE1ZantrAk7UND4mYMJ6BFW8v2xXeMVi",
        "name": "alumni2012"
      },
      {
        "id": 61,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1cFxKTBvQshx1mFDdBbQbRK62VdvBWOpd",
        "name": "alumni2012"
      },
      {
        "id": 62,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1dbihvq07XCYljbwCna1LLNKaZfmriGGH",
        "name": "alumni2012"
      },
      {
        "id": 63,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1f9aGL7Z_MZphcDeOY4XjJRlzQjNWsrUC",
        "name": "alumni2012"
      },
      {
        "id": 64,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1g3Tx-3tChEawPKQktnw302el0X59UdGF",
        "name": "alumni2012"
      },
      {
        "id": 65,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1hPBcAOn7BS6M9i_AEjMGvkCtbdKpr2E0",
        "name": "alumni2012"
      },
      {
        "id": 66,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1hlaH70N6r8GL3dVwTXe_HNhploLoU19J",
        "name": "alumni2012"
      },
      {
        "id": 67,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1iMmlx88v5kKsGwyid6jTa5kXDMwCVr6m",
        "name": "alumni2012"
      },
      {
        "id": 68,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1j2ADOl0G1bZ4l2bELQiHNkqZ8QuqAloL",
        "name": "alumni2012"
      },
      {
        "id": 69,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1l0g3YEv6e_eMPl2AUsft5HfDP_ETsAbN",
        "name": "alumni2012"
      },
      {
        "id": 70,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1mNG_w3hui1VB9Utjy3NAUH3c4I1GjPp9",
        "name": "alumni2012"
      },
      {
        "id": 71,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1oaJRwMld4zvJZwYGlCTnBmbxFLDUw5DB",
        "name": "alumni2012"
      },
      {
        "id": 72,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1owSlMJxig2qlzrSF6IONoQLJTsqYWo0A",
        "name": "alumni2012"
      },
      {
        "id": 73,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1p8RsGnZ_A2TXHXjvhUyHYopFyUFuAoXx",
        "name": "alumni2012"
      },
      {
        "id": 74,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1r7ysKT2mNptEVt94EKdk-Yj33R1j7pOD",
        "name": "alumni2012"
      },
      {
        "id": 75,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1rFz7cl4NvGHZFVmaqXHBC7XzZC9xwPEF",
        "name": "alumni2012"
      },
      {
        "id": 76,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1wKMFxG68cfy0cUCSDUH2AoqKMrXLAWN3",
        "name": "alumni2012"
      },
      {
        "id": 77,
        "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1yqTdxevDYVgZjazkDMkeUjeLoLNsZJIY",
        "name": "alumni2012"
      },
        {
          "id": 79,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=11u5AUqyQPzPqzpILSsG913yE6VxEaE0x",
          "name": "alumni2016"
        },
        {
          "id": 80,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=160vZn1DgVlye_t1hE6QujGTj3dMr10Sb",
          "name": "alumni2016"
        },
        {
          "id": 81,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1G2npr7nzqQBi1weH7ZcqXgOmdl51hFHy",
          "name": "alumni2016"
        },
        {
          "id": 82,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1H4hNEw0ft0Xyndp27zgWNZirIu7Lg_oS",
          "name": "alumni2016"
        },
        {
          "id": 83,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1ILtRZ_N4ygwppN0RwOReoO_Xw2yftiPY",
          "name": "alumni2016"
        },
        {
          "id": 84,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1SnL-ynTJ3pT4rMdUezx1fmrbIV6Vs_dL",
          "name": "alumni2016"
        },
        {
          "id": 85,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1s7shaLobvGYMrqOOyZzWjhkXy8g0GsmI",
          "name": "alumni2016"
        },
        {
          "id": 86,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1wDi-GuAmUgGCnvYRoP1h_iC8V_UXl-Bb",
          "name": "alumni2016"
        },
        {
          "id": 87,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1mimsuiDuF8bCwnV8gOreQtM9Ru_OE6GH",
          "name": "alumni2021"
        },
        {
          "id": 88,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1BFftGye5ovrOkiddb5cCA5KaD9M-W4SB",
          "name": "alumni2021"
        },
        {
          "id": 89,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1NOisNIOdJ_Vyx94mE1Di2PgqX_R5OPON",
          "name": "alumni2021"
        },
        {
          "id": 90,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1TBREc4MvWFpUla8BxaOCog7nOLpqGxCf",
          "name": "alumni2021"
        },
        {
          "id": 92,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1kytTmUk72lrvFAxASeW_-x_y4Qa0gSNh",
          "name": "alumni2021"
        },
        {
          "id": 93,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1fLWs7aiBBBbiJtdYVdjbC7TD2Pf1czKl",
          "name": "alumni2021"
        },
        {
          "id": 94,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1_eQPfqXq7a0-69D7FI5395DfV1sLbq9_",
          "name": "alumni2021"
        },
        {
          "id": 95,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1Y7-w5bKgsQPHGX71Mgt9lH1tXyIlqE2w",
          "name": "alumni2021"
        },
        {
          "id": 96,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1U0Wl5J87lV9fmQysD8O7Lcciwwt0gGG3",
          "name": "alumni2023"
        },
        {
          "id": 97,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=17ZoO1guHBHheDmN0mMkC2nJ_vocI6edg",
          "name": "alumni2023"
        },
        {
          "id": 98,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1OPqt64OxJCokXhUnA7klVlUSNpKAJf4c",
          "name": "alumni2023"
        },
        {
          "id": 99,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1xJiXSkQOHqTr_jEMVELIMS473VIauIGB",
          "name": "alumni2023"
        },
        {
          "id": 100,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1jFFq1ij5gmwH7b7UpIHvzfHdpbxnmSVU",
          "name": "alumni2023"
        },
        {
          "id": 101,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1396zOjG_9-0z5huxjyWDZVITrvGyLu-7",
          "name": "alumni2023"
        },
        {
          "id": 102,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1vxa5n4Npqh2m-tV8QzBRec2ed2d_z3e2",
          "name": "alumni2023"
        },
        {
          "id": 103,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1iZfYQ8hU4tWyspAn5y-XmNmmlT73ioqg",
          "name": "alumni2023"
        },
        {
          "id": 104,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1jBJzIibGZrxI-5UoMsFzwoo_9lSPe_Hl",
          "name": "alumni2023"
        },
        {
          "id": 105,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1z4Xaz7VJ58eeG0AbncVXnrXP7HBD8WhO",
          "name": "alumni2023"
        },
        {
          "id": 106,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1mgWDZblo7CQUMn3T4A0PlSKgEYe026RT",
          "name": "alumni2023"
        },
        {
          "id": 107,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1vxa5n4Npqh2m-tV8QzBRec2ed2d_z3e2",
          "name": "alumni2023"
        },
        {
          "id": 108,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1KGTqnlUHuI2NsN-8s8JdfXZCo9Ugakfy",
          "name": "alumni2023"
        },
        {
          "id": 109,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1m6FqiihofC692BTUsuZ3IjUQqvY1SU9s",
          "name": "alumni2023"
        },
        {
          "id": 110,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1kmCDJnHtINlceEsCL3UL-tabQg6VFO4g",
          "name": "alumni2023"
        },
        {
          "id": 111,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1QfAdGushWIMnQUdA2B5daOLC3ofzeNav",
          "name": "alumni2023"
        },
        {
          "id": 112,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1WPaC6pD8bP4O65sE81JP3w-S6QiCVWSL",
          "name": "alumni2023"
        },
        {
          "id": 113,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1Wpttt-b4IimfRp3bp2ZR2sNqRTvOV44a",
          "name": "alumni2023"
        },
        {
          "id": 114,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1Hz5lduCTAc2LzjeHwwP1xgMznqJLrUyM",
          "name": "alumni2023"
        },
        {
          "id": 115,
          "photo": "https://drive.google.com/uc?export=view&pen?usp=forms_web&id=1DvCZLA_o5Qtl4r5Dig-cnha_cbVuAIC0",
          "name": "alumni2023"
        },
      

];

